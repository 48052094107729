import '../assets/styles/Main.scss';
import {GitHub, LinkedIn} from "@mui/icons-material";


export default function Main() {

    const portrait =  require("../assets/images/portrait.jpg")

    return (
        <div className="container">
            <div className="about-section">
                <div className="image-wrapper">
                    <img src={portrait}
                         alt="Avatar"/>
                </div>
                <div className="content">
                    <div className="social_icons">
                        <a href="https://github.com/colinluetzelschwab" target="_blank" rel="noreferrer"><GitHub/></a>
                        <a href="https://www.linkedin.com/in/colin-lützelschwab-09224219a/" target="_blank" rel="noreferrer"><LinkedIn/></a>
                    </div>
                    <h1>Colin Lützelschwab</h1>
                    <p>Full Stack Engineer</p>

                    <div className="mobile_social_icons">
                        <a href="https://github.com/colinluetzelschwab" target="_blank" rel="noreferrer"><GitHub/></a>
                        <a href="https://www.linkedin.com/in/colin-lützelschwab-09224219a/" target="_blank" rel="noreferrer"><LinkedIn/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}